import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component';
import {MaterializeModule, MzCardModule, MzIconModule} from "ngx-materialize";



@NgModule({
    declarations: [MaintenanceComponent],
    exports: [
        MaintenanceComponent
    ],
    imports: [
        CommonModule,
        MzCardModule,
        MzIconModule,
        MaterializeModule
    ]
})
export class MaintenanceModule { }
