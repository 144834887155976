import {Component, OnInit} from '@angular/core';
import {Request} from '../../models/request/request';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    request = new Request();

    constructor() {
    }

    ngOnInit() {
    }

    clear() {
        this.request = new Request();
    }

}
