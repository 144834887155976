import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MzCardModule,
    MzIconModule,
    MzInputModule,
    MzNavbarModule,
    MzParallaxModule, MzSelectModule, MzSidenavModule, MzTabModule,
    MzTextareaModule
} from 'ngx-materialize';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MzNavbarModule,
        MzParallaxModule,
        MzIconModule,
        MzCardModule,
        MzInputModule,
        MzTextareaModule,
        MzSelectModule,
        MzTabModule,
        MzSidenavModule
    ],
    exports: [
        MzNavbarModule,
        MzParallaxModule,
        MzIconModule,
        MzCardModule,
        MzInputModule,
        MzTextareaModule,
        MzSelectModule,
        MzTabModule,
        MzSidenavModule
    ]
})
export class MaterializeModule {
}
