import {Component, OnInit} from '@angular/core';
import {$e} from "codelyzer/angular/styles/chars";

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

    hover = {
        free: false,
        standard: true,
        pro: false
    };

    constructor() {
    }

    ngOnInit() {
    }

    private reset() {
        this.hover = {
            free: false,
            standard: false,
            pro: false
        };
    }

    changeHover($event, type: string) {
        this.reset();

        if ($event.type === 'mouseenter') {
            this.hover[type] = true;
        }
    }

}
