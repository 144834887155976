import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        const memoryText = $('.memory-text');
        const managementText = $('.management-text');
        managementText.hide();

        setInterval(() => {
            if (memoryText.is(':visible')) {
                memoryText.fadeToggle(() => {
                    managementText.fadeToggle();
                });
            } else {
                managementText.fadeToggle(() => {
                    memoryText.fadeToggle();
                });
            }
        }, 4000);
    }

}
