import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-of-books',
  templateUrl: './number-of-books.component.html',
  styleUrls: ['./number-of-books.component.scss']
})
export class NumberOfBooksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
