import {Component, Input, OnInit} from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
    selector: 'app-parallax-title',
    templateUrl: './parallax-title.component.html',
    styleUrls: ['./parallax-title.component.scss']
})
export class ParallaxTitleComponent implements OnInit {

    @Input()
    title: string;

    letters: string[];

    constructor() {
    }

    ngOnInit() {
        this.letters = this.title.split('');

        setTimeout(() => {
            this.initializeTextEffect();
        });
    }

    initializeTextEffect() {
        anime.timeline({loop: true})
            .add({
                targets: 'h1 .letter',
                scale: [0, 1],
                duration: 1500,
                elasticity: 600,
                delay: (el, i) => 45 * (i + 1)
            }).add({
                targets: 'h1',
                opacity: 0,
                duration: 1000,
                easing: 'easeOutExpo',
                delay: 5000
            });
    }

}
