import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './shared/components/header/header.component';
import {MaterializeModule} from './shared/modules/materialize/materialize.module';
import {FooterComponent} from './shared/components/footer/footer.component';
import {HomeModule} from './home/modules/home/home.module';
import {NgxPageScrollModule} from "ngx-page-scroll";
import {MaintenanceModule} from "./maintenance/maintenance.module";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterializeModule,
        NgxPageScrollModule,
        HomeModule,
        MaintenanceModule
    ],
    providers: [],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
