import {NgModule} from '@angular/core';
import {ParallaxTitleComponent} from '../../components/parallax-title/parallax-title.component';
import {MaterializeModule} from '../../../shared/modules/materialize/materialize.module';
import {CommonModule} from '@angular/common';
import {HomeComponent} from '../../pages/home/home.component';
import {AnimateOnScrollModule} from 'ng2-animate-on-scroll';
import {AboutComponent} from '../../components/about/about.component';
import {ContactComponent} from '../../components/contact/contact.component';
import {FormsModule} from '@angular/forms';
import {CtaComponent} from '../../components/cta/cta.component';
import {HighlightModule} from 'ngx-highlightjs';
import {FeaturesComponent} from '../../components/features/features.component';
import {PricingComponent} from '../../components/pricing/pricing.component';
import {PartnerComponent} from '../../components/partner/partner.component';
import {InterestComponent} from '../../components/interest/interest.component';
import {NumberOfBooksComponent} from '../../components/number-of-books/number-of-books.component';

@NgModule({
    declarations: [
        HomeComponent,
        ParallaxTitleComponent,
        AboutComponent,
        ContactComponent,
        PricingComponent,
        PartnerComponent,
        FeaturesComponent,
        CtaComponent,
        InterestComponent,
        NumberOfBooksComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterializeModule,
        AnimateOnScrollModule.forRoot(),
        HighlightModule
    ]
})
export class HomeModule {
}
